.menu {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    width: 100%;
}

.right-side {
    width: 100%;
    margin-left: 10px;
}
@tailwind base;
@tailwind components;
@tailwind utilities;
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.container {
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  margin: 0px;
  padding: 40px;
  padding-right: 0px;
}

.main {
  width: 100%;
  padding: 0 50px;
}

.container-1 {

}

.image-container {
  text-align: center;
  margin-bottom: 20px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

}

.image-container-1 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 20px 0;
}

.image-container-1 p {
  text-align: center;
  font-weight: bold;
  font-size: medium;
}

.image-container-1 img {
  margin: 20px 0;
}

.logo {
  max-width: 150px;
  margin-bottom: 5px;
}

.content {
  width: 100%;
}

.author-info {
  margin-bottom: 20px;
}

.author-row {
  display: flex;
  justify-content: space-between;
  /*margin-bottom: 1px;*/
}

.author-column {
  width: 48%; /* Cada coluna ocupa metade da largura */
}

.content-columns {
  display: flex;
  flex-direction: row;
  /*justify-content: flex-start;*/
}

.left-side,
.right-side {
  width: 50%; /* Cada lado ocupa metade da largura da seção pai */
  padding-right: 50px;
}

.data-section p,
.co-authors-section p {
  margin: 5px 0;
}

.author-list {
  list-style-type: none;
  padding: 0;
}

.author-list li {
  margin-bottom: 5px;
}
.box {
}
.author-column{
  margin: 1px;
  padding: 3px 6px;
  background-color: #d3d3d3;
  width: 100%;
}
.author-column p{
  margin-bottom: 1px;
}

p, li{
  font-size: 10pt;
}

h5{
  font-size: 11pt;
}
@media print {
    @page {
        size: A4;
        margin-bottom: 20mm;
        margin-top: 20mm;
    }

    body {
        width: 210mm; /* A4 width */
        height: 297mm; /* A4 height */
      }
}

body, html {
    height: 100%;
    width: 100%;
    margin: 0;
    padding: 0;
}

.container {
    width: 100vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    margin: 0px;
    padding: 40px;
    padding-right: 0px;
}

.image-container {
    text-align: center;
    margin-bottom: 20px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

}

.logo {
    max-width: 150px;
    margin-bottom: 5px;
}

.content {
    width: 100%;
}

.author-info {
    margin-bottom: 20px;
}

.author-row {
    display: flex;
    justify-content: space-between;
    margin-bottom: 1px;
}

.author-column {
    width: 48%; /* Cada coluna ocupa metade da largura */
}

.content-columns {
    display: flex;
}

.left-side,
.right-side {
    width: 50%; /* Cada lado ocupa metade da largura da seção pai */
}

.data-section p,
.co-authors-section p {
    margin: 5px 0;
}

.author-list {
    list-style-type: none;
    padding: 0;
}

.author-list li {
    margin-bottom: 5px;
}
.box {
}
.author-column{
    background-color: #d3d3d3;
    width: 100%;
}
.author-column p{
    margin-bottom: 1px;
}

p, li{
    font-size: 10pt;
}

h5{
    font-size: 11pt;
}